// rate-limit.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { RateLimitDialogComponent } from './rate-limiting-dialog';

@Injectable()
export class RateLimitInterceptor implements HttpInterceptor {

  constructor(private dialogService: NbDialogService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check if this is a rate limit error (429)
        if (error.status === 429) {
          // Only show dialog for login-related endpoints
          if (request.url.includes('auth.login') ||
            request.url.includes('users.sendPasswordRecovery') ||
            request.url.includes('users.create')) {

            this.showRateLimitDialog(error.error, {
              isLoginCall: request.url.includes('auth.login'),
              isPasswordRecovery: request.url.includes('users.sendPasswordRecovery')
                || request.url.includes('users.create')
            });
          }
        }
        return throwError(error);
      })
    );
  }

  private async showRateLimitDialog(errorData: any, options: { isLoginCall: boolean, isPasswordRecovery: boolean }) {
    this.dialogService.open(RateLimitDialogComponent, {
      context: {
        message: errorData.message || 'Te veel login pogingen. Probeer het opnieuw over 15 minuten.',
        remainingTime: errorData.remainingTime || 15,
        isLoginCall: options.isLoginCall,
        isPasswordRecovery: options.isPasswordRecovery
      },
      hasBackdrop: true,
      closeOnBackdropClick: true
    });
  }
}
