// rate-limit-dialog.component.ts
import { Component, Input } from '@angular/core';
import {NbButtonModule, NbCardModule, NbDialogRef, NbIconModule} from '@nebular/theme';
import { Router } from '@angular/router';
import {NgIf} from "@angular/common";
import {faTriangleExclamation} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";

@Component({
  selector: 'ngx-rate-limit-dialog',
  standalone: true,
  imports: [
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NgIf,
    FaIconComponent
  ],
  template: `
    <nb-card>
      <nb-card-header style="background-color: #ffaa00; color: white;">
          <h4 style="text-align: center;font-weight: bold">{{isLoginCall ? 'LOGIN' : ''}} BEPERKING</h4>
      </nb-card-header>
      <nb-card-body>
        <div style="display: flex; flex-direction: column; align-items: center; text-align: center;">
          <fa-icon [icon]="faTriangleExclamation" status="warning"
                   style="font-size: 3rem; color: #ffaa00; margin-bottom: 1rem;"></fa-icon>
          <p><b>{{ message }}</b></p>
          <p *ngIf="isLoginCall">Als je je wachtwoord bent vergeten, gebruik dan de "Wachtwoord vergeten" functie.</p>
        </div>
      </nb-card-body>
      <nb-card-footer style="display: flex; justify-content: space-between;">
        <button *ngIf="!isPasswordRecovery" nbButton status="primary" (click)="goToForgotPassword()">Wachtwoord vergeten</button>
        <button nbButton status="basic" (click)="close()">Sluiten</button>
      </nb-card-footer>
    </nb-card>
  `
})
export class RateLimitDialogComponent {
  @Input() message: string = 'Te veel login pogingen. Probeer het opnieuw na 20 minuten.';
  @Input() remainingTime: number = 15;
  @Input() isLoginCall : boolean = false;
  @Input() isPasswordRecovery : boolean = false;


  constructor(
    private dialogRef: NbDialogRef<RateLimitDialogComponent>,
    private router: Router
  ) {}

  close() {
    this.dialogRef.close();
  }

  goToForgotPassword() {
    this.dialogRef.close();
    this.router.navigateByUrl('/auth/forgot-password');
  }

  protected readonly faTriangleExclamation = faTriangleExclamation;
}
